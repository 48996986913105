@import '../../../../styles/sharedStyle.index.scss';
@import '../../../../styles/mixin.scss';

$dropdownItemsMarginRight: 15px;
$dropdownMinWidth: 88px;
$dropdownToggleButtonHeight: 19px;
$copyLinkButtonHorizontalPadding: 48px;
$toastTopOffset: 40px;

.timer-share-dropdown {
  position: relative;
  display: inline-block;
  min-width: 88px;
  transition: width 0.4s ease-out;
  flex: 0 0 auto;

  @include mobileView {
    left: 0px;
  }

  .timer-share-toggle-button {
    @include displayFlex(flex-start, center);
    @include fontBody;
    @include themed(color, textPrimary);
    height: 19px;
    padding: 0;
    border-radius: 5px;
    @include themed(border, transparent);
    @include themed(background-color, backgroundTransparent);
    white-space: nowrap;

    .timer-share-icon {
      margin-left: 8px;
      @include themed(stroke, textPrimary);
      width: 16px;
      height: 16px;
    }
  }

  .timer-share-menu {
    position: absolute;
    top: -15px;
    right: -3px;
    @include themed(background-color, backgroundPrimary);
    width: 320px;
    overflow: hidden;
    height: 0;
    transition: height 0.4s ease-out;
    z-index: 1000;
    border-radius: 16px;

    @include mobileView {
      width: 240px;
    }

    .timer-share-header {
      @include displayFlex(space-between, center);
      padding: 24px;

      span {
        @include fontBody;
        font-weight: 500;
        @include themed(color, textSecondary);
        flex: 1;
      }

      .timer-share-close-button {
        padding: 8px;
        @include themed(border, none);
        @include themed(background, none);
        cursor: pointer;
        @include themed(color, brandPrimary);
      }
    }

    .timer-share-copy-link-button {
      margin: 0 24px 24px;
      width: calc(100% - $copyLinkButtonHorizontalPadding);
      padding: 10px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      @include fontBody;
      @include themed(background-color, backgroundBrandPrimary);
      @include themed(color, textContrast);
    }
  }

  &.timer-share-dropdown--open {
    .timer-share-toggle-button {
      transition: 0.5s;

      span {
        @include themed(color, textBrandAlt);
      }

      .timer-share-icon {
        display: none;
      }
    }

    .timer-share-menu {
      height: 165px;
      @include themed(box-shadow, borderPrimary, 0px 4px 0px 0px);
      @include themed(border, borderPrimary, 1px solid);

      .timer-share-header {
        .timer-share-icon {
          display: none;
        }

        span {
          @include themed(color, textBrandAlt);
        }
      }
    }
  }

  .timer-share-toast {
    position: absolute;
    top: calc(100% + $toastTopOffset);
    transform: translateX(-50%);
    padding: 12px 24px;
    left: 40px;
    border-radius: 8px;
    @include themed(background-color, backgroundBrandSecondary);
    @include themed(color, textPrimary);
    animation: timer-share-fade-in-out 3s ease;
    z-index: 1001;
    white-space: nowrap;

    @include mobileView {
      padding: 12px 16px;
    }
  }
}

@keyframes timer-share-fade-in-out {
  0% {
    opacity: 0;
    transform: translate(-50%, 8px);
  }
  15% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  85% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -8px);
  }
}

@include mobileView {
  .plan-view-selection {
    display: none;
    margin-top: 2px;
  }

  body {
    &:has(.display-stage .chat .stage-tasks-container),
    &:has(.display-stage .chat .stage-planner-container) {
      .plan-view-selection {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .plan-view-selection {
        display: none !important;
      }
    }
  }
}

// hide dropdown when message dislike feedback modal is open
body {
  &:has(.chat .message .dislike-feedback-container) {
    .plan-view-selection {
      display: none !important;
    }
  }
}
