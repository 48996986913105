@import '../../styles/sharedStyle.index.scss';

// Page layout styles
.timer-page {
  height: 100vh;
  height: calc(var(--availableViewportHeight) * 100);
  @include displayFlex(flex-start, center, column);
  @include themed(background-color, backgroundPrimary);
  padding: 40px 20px 40px 40px;

  .timer-page-content {
    @include displayFlex(center, center, column);
    flex: 1;
    @include themed(background-color, backgroundPrimary);
    padding: 20px;
  }

  .timer-page-header {
    width: 100%;
    padding: 0px 20px;
  }

  .timer-page-header-content {
    @include displayFlex(space-between, center);
    width: 100%;

    .timer-share-icon {
      margin-left: 8px;
      margin-right: 0px;
    }

    .timer-share-dropdown {
      left: 0px;
    }
  }

  .timer-page-header-title {
    @include fontSmallTitle;
    @include themed(color, textBrand);
    white-space: nowrap;
  }
}

@include mobileView {
  .timer-page {
    padding: 0 $mobileScreenEdgeSpacing;

    .stage-header {
      display: block;
    }
    .timer-page-header{
      height: $mobileHeaderHeight;
      padding: 0;
      .timer-page-header-content{
        padding: 0;
        height: 100%;
        @include displayFlex(space-between, center);
      }
    }
  }
}